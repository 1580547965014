<template>
  <v-row class="Product">
    <v-col class="d-flex justify-center" v-for="item in arrayOfProduct" :key="item.token">
      <v-dialog v-model="openProductDialog[item.token]" max-width="800" :retain-focus="false" :fullscreen="$vuetify.breakpoint.xs">
        <template v-slot:activator="{ on, attrs }">
          <v-card elevation="3" width="400">
            <v-card-text v-bind="attrs" v-on="on">
              <v-img v-if="item.image !== undefined && item.image !== null" :src="url() + item.image.path" height="300px">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0 align-center justify-ceter">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-card-text>
            <v-card-title class="black--text">{{ item.nameRu }}</v-card-title>
            <v-card-title class="black--text">{{ item.price }} руб.</v-card-title>
            <div>
              <v-divider class="mx-4"></v-divider>
              <ButtonsGroupForCart :item="item" />
            </div>
          </v-card>
        </template>
        <v-card class="Product">
          <v-card-title class="mb-2">{{ item.nameRu }}</v-card-title>
          <v-card-text>
            <v-row class="mb-2">
              <v-col>
                <v-img v-if="item.image !== undefined && item.image !== null" :src="url() + item.image.path" max-height="300px" max-width="400px">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0 align-center jystify-ceter">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row> </template
                ></v-img>
              </v-col>
              <v-col>
                <v-card-text class="pa-0 text-subtitle-1 black--text">{{ item.descriptionRu }}</v-card-text>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="my-4 text-subtitle-1 black--text">Жиры: {{ item.fats }}</div>
            <div class="my-4 text-subtitle-1 black--text">Белки: {{ item.proteins }}</div>
            <div class="my-4 text-subtitle-1 black--text">Углеводы: {{ item.carbohydrates }}</div>
            <div class="my-4 text-subtitle-1 black--text">Калории: {{ item.calories }}</div>
            <div class="my-4 text-subtitle-1 black--text">Вес: {{ item.weight }} гр.</div>
            <v-divider></v-divider>
            <v-row class="mx-1 justify-space-between">
              <div class="my-4 text-subtitle-1 black--text">Цена: {{ item.price }} руб.</div>
              <ButtonsGroupForCart :item="item" />
            </v-row>
          </v-card-text>
          <v-card-actions class="align-items-center justify-space-around flex-wrap">
            <v-btn text class="mx-2 text-none" color="grey darken-3" @click="openProductDialog[item.token] = false">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import config from '@/config/config';
import ButtonsGroupForCart from '@/components/ButtonsGroupForCart';
export default {
  data() {
    return {
      arrayOfProduct: this.product,
      counter: 0,
      openProductDialog: {}
    };
  },
  components: { ButtonsGroupForCart },
  props: {
    product: {
      type: Array,
      required: true
    }
  },
  methods: {
    url() {
      return window.location.origin + '/';
    },
    ...mapActions('menu', ['addToCart']),
    ...mapActions('menu', ['setCafeToken']),
    ...mapActions('menu', ['removeFromCart']),
    ...mapActions('menu', ['incrementCounter']),
    ...mapActions('menu', ['decrementCounter'])
  }
};
</script>
<style scoped>
.Product {
  font-family: RussianRail;
}
</style>
