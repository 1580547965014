<template>
  <div>
    <v-fab-transition>
      <v-btn class="BucketButton" fab color="red" v-show="products.length !== 0" @click="dialog = true">
        <v-badge color="rgba(57, 74, 88, 1)" :content="products.length"><v-icon>mdi-basket-outline</v-icon></v-badge></v-btn
      >
    </v-fab-transition>

    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xs" persistent max-width="1280">
      <v-card>
        <v-card-title class="pb-4">Корзина</v-card-title>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-row>
              <v-col class="d-flex mb-0">
                <v-card-text>Позиция</v-card-text>
                <v-card-text></v-card-text>
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
            <v-row v-for="item in this.products" :key="item.id">
              <v-col v-if="item.count > 0" class="d-flex mx-3 pb-2">
                <v-img v-if="item.image !== undefined && item.image !== null" :src="url() + item.image.path" height="50px" width="50px">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0 align-center jystify-ceter">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <v-card-text>{{ item.nameRu }}</v-card-text>
                <ButtonsGroupForCart :item="item" />
              </v-col>
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <v-card-title>Сумма к оплате: {{ sum }} руб.</v-card-title>
            <v-divider class="mx-4"></v-divider>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-form ref="productForm" @submit.prevent="checkInput">
              <v-card-text>
                <span class="font-weight-medium">Фамилия И.О.</span>
                <v-text-field
                  v-model="order.fullName"
                  :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                  autocomplete="name"
                  :disabled="
                    clientData !== undefined &&
                    clientData !== null &&
                    clientData.fullName !== undefined &&
                    clientData.fullName !== null &&
                    clientData.fullName.length > 0
                  "
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  required
                  persistent-hint
                  label="Фамилия и инициалы"
                  hint="Введите фамилию и инициалы"
                ></v-text-field>
                <span class="font-weight-medium">Почта</span>
                <v-text-field
                  v-model="order.mail"
                  :rules="validationRules.notEmpty.concat(validationRules.emailForm)"
                  :disabled="
                    clientData !== undefined && clientData !== null && clientData.mail !== undefined && clientData.mail !== null && clientData.mail.length > 0
                  "
                  autocomplete="email"
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  required
                  type="mail"
                  label="Email"
                  persistent-hint
                  hint="Введите почту"
                ></v-text-field>
                <span class="font-weight-medium">Телефон</span>
                <v-text-field
                  v-model="order.phone"
                  :rules="validationRules.notEmpty.concat(validationRules.numberForm)"
                  :disabled="
                    clientData !== undefined &&
                    clientData !== null &&
                    clientData.phone !== undefined &&
                    clientData.phone !== null &&
                    clientData.phone.length > 0
                  "
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  required
                  label="Номер"
                  persistent-hint
                  hint="Введите номер телефона"
                  type="number"
                  hide-spin-buttons
                  value="+"
                  min="0"
                ></v-text-field>
                <!-- <v-text-field
                  v-model="order.authCode"
                  :rules="validationRules.notEmpty"
                  :disabled="
                    clientData !== undefined &&
                    clientData !== null &&
                    clientData.authCode !== undefined &&
                    clientData.authCode !== null &&
                    clientData.authCode.length > 0
                  "
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  required
                  label="Код"
                  persistent-hint
                  hint="Введите код"
                  hide-spin-buttons
                ></v-text-field> -->
                <v-autocomplete
                  v-model="order.takeoutToken"
                  :rules="validationRules.notEmpty"
                  hide-details="auto"
                  :items="takeoutsList()"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  required
                  label="Точка доставки"
                  persistent-hint
                  hint="Выберите точку доставки"
                ></v-autocomplete>
                <v-text-field
                  v-model="order.comment"
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  persistent-hint
                  hint="Введите комментарий (при необходимости)"
                  label="Комментарий (при необходимости)"
                ></v-text-field>
                <v-checkbox v-model="enabled" label="Согласие с условием оферты" hide-details class="shrink mr-2 mt-0"></v-checkbox>
                <a href="/Оферта.pdf" target="_blank">Условия оферты</a>
              </v-card-text>
            </v-form>
          </v-col>
        </v-row>
        <v-card-title>{{ errorMessage }}</v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="mx-2 text-none" color="red" text elevation="0" type="submit" :disabled="sum === 0 || !enabled" v-on:click="createOrderClick"
            >Подтвердить</v-btn
          >
          <v-btn class="mx-2 text-none" text elevation="0" v-on:click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import config from '@/config/config';
import ButtonsGroupForCart from '@/components/ButtonsGroupForCart';
export default {
  data() {
    return {
      order: {
        fullName: '',
        phone: '',
        mail: '',
        comment: '',
        cafeToken: '',
        takeoutToken: localStorage.getItem('takeout')
        // authCode: ''
      },
      errorMessage: '',
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        numberForm: [(v) => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера']
      },
      enabled: false,
      dialog: false
    };
  },
  components: {
    ButtonsGroupForCart
  },
  computed: {
    products() {
      const itemsArray = [];
      if (this.productsInCart !== undefined && this.productsInCart !== null && this.productsInCart.length > 0) {
        this.productsInCart.forEach((item) => itemsArray.push(item));
      }
      return itemsArray;
    },
    sum() {
      let sum = 0;
      if (this.productsInCart !== undefined && this.productsInCart !== null && this.productsInCart.length > 0) {
        this.productsInCart.forEach((item) => {
          if (item.count > 0) sum += item.price * item.count;
        });
      }
      return sum;
    },
    clientData() {
      let data = null;
      if (this.loginClientData !== undefined && this.loginClientData !== null) {
        data = {};
        data.fullName = this.loginClientData.name;
        data.phone = this.loginClientData.phone;
        data.authCode = this.loginClientData.authCode;
        data.mail = this.loginClientData.email;
        data.comment = '';
        data.cafeToken = '';
        data.takeoutToken = localStorage.getItem('takeout');
      }
      return data;
    },
    ...mapGetters('menu', ['menuList']),
    ...mapGetters('client', ['loginClientData']),
    ...mapGetters('order', ['createOrderMessage']),
    ...mapGetters('order', ['createOrderResult']),
    ...mapGetters('order', ['paymentToken']),
    ...mapGetters('order', ['orderNum']),
    ...mapState('menu', ['productsInCart'])
  },
  methods: {
    url() {
      return window.location.origin + '/';
    },
    checkInput() {
      return true;
    },
    createOrderClick() {
      if (this.$refs.productForm.validate()) {
        this.order.cafeToken = localStorage.getItem('cafeToken');
        let items = [];
        this.products.forEach((item) => {
          if (item.count > 0) items.push({ token: item.token, count: item.count });
        });
        this.order.items = items;
        this.createOrder(this.order)
          .then(() => {
            if (this.createOrderResult === true) {
              this.closeDialog();
              this.order.fullName = '';
              this.order.phone = '';
              this.order.mail = '';
              localStorage.removeItem('cafeToken');
              localStorage.removeItem('cartProducts');
              this.$router.push('/payment?token=' + this.paymentToken + '&order=' + this.orderNum);
              // this.clearCart();
            }
          })
          .catch(() => {
            this.errorMessage = this.createOrderMessage;
          });
      }
    },
    takeoutsList() {
      let takeoutsArray = JSON.parse(localStorage.getItem('takeoutsArr'));
      let formedTakeouts = [];
      if (takeoutsArray !== undefined && takeoutsArray !== null) {
        takeoutsArray.forEach((takeout) =>
          formedTakeouts.push({
            value: takeout.token,
            text: takeout.nameFullRu
          })
        );
      }
      return formedTakeouts;
    },
    closeDialog() {
      this.order.comment = '';
      this.order.cafeToken = '';
      this.dialog = false;
    },
    checkClientForOrder() {
      let clientInfo = JSON.parse(localStorage.getItem('client'));
      if (clientInfo !== undefined && clientInfo !== null) {
        if (clientInfo.name !== undefined && clientInfo.name !== null) this.order.fullName = clientInfo.name;
        if (clientInfo.phone !== undefined && clientInfo.phone !== null) {
          if (clientInfo.phone.startsWith('+')) {
            this.order.phone = clientInfo.phone.substring(1);
          } else this.order.phone = clientInfo.phone;
        }
        if (clientInfo.email !== undefined && clientInfo.email !== null) this.order.mail = clientInfo.email;
        if (clientInfo.authCode !== undefined && clientInfo.authCode !== null) this.order.authCode = clientInfo.authCode;
      }
    },
    ...mapActions('cafe', ['fetchCurrentCafe']),
    ...mapActions('order', ['createOrder']),
    ...mapActions('menu', ['clearCart'])
  },

  watch: {
    loginClientData(newVal) {
      if (newVal !== null) {
        this.order.fullName = newVal.name;
        this.order.phone = newVal.phone;
        this.order.authCode = newVal.authCode;
        this.order.mail = newVal.email;
        this.order.comment = '';
        this.order.cafeToken = '';
        this.order.takeoutToken = localStorage.getItem('takeout');
      } else {
        this.order.fullName = '';
        this.order.phone = '';
        this.order.mail = '';
        this.order.comment = '';
        this.order.cafeToken = '';
        this.order.takeoutToken = localStorage.getItem('takeout');
        this.order.authCode = '';
      }
    }
  },
  mounted() {
    this.checkClientForOrder();
  }
};
</script>
<style scoped>
.BucketButton {
  position: fixed;
  bottom: 7%;
  right: 5%;
}
</style>
