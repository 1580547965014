<template>
  <div>
    <v-app-bar elevation="0" hide-on-scroll color="white">
      <img class="mr-3" src="../../assets/Лого Вокзалы России 1.svg" height="50px" />
      <!-- <v-icon height="85">$DrzhLogo</v-icon> -->
      <v-spacer></v-spacer>
      <!-- <v-btn href="https://vk.com/railway_stations_russia" icon>
        <v-icon>$VkLogo</v-icon>
      </v-btn>
      <v-btn href="https://t.me/railway_stations_russia" icon>
        <v-icon>$TelegramLogo</v-icon>
      </v-btn> -->
      <!-- {{ loginClientData }} -->
      <!-- <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :color="getClientIconColor" large @click="openDialog = true" v-bind="attrs" v-on="on">mdi-account-outline</v-icon>

        </template>
        <v-list dense>
          <v-list-item v-if="loginClientData !== null">
            <v-list-item-content>
              <v-list-item-title>{{ loginClientData.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/client" v-if="loginClientData !== null">
            <v-list-item-content>
              <v-list-item-title>Заказы</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="clientLogoutClick" v-if="loginClientData !== null">
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="showClientLoginDialog = true" v-if="loginClientData === null">
            <v-list-item-content>
              <v-list-item-title>Войти</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <div class="d-flex align-center mx-5">
        <div class="d-none d-sm-flex">
          Центр поддержки клиентов <br />
          8 800 775 69 29
        </div>
      </div>
      <img class="mr-3" src="../../assets/RZD.svg" />
    </v-app-bar>
    <!-- <ClientLoginDialog v-model="showClientLoginDialog" /> -->
    <v-dialog v-model="showClientLoginDialog" max-width="600">
      <v-card>
        <v-form ref="form" @submit.prevent="checkInput">
          <v-card-title>Вход</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Почта</span>
            <v-text-field
              v-model="client.email"
              :rules="validationRules.notEmpty.concat(validationRules.emailForm)"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              required
              persistent-hint
              label="Почта"
              hint="Введите почту"
              autocomplete="email"
            ></v-text-field>
            <span class="font-weight-medium">Код</span>
            <v-text-field
              v-model="client.authCode"
              :rules="validationRules.notEmpty"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              required
              persistent-hint
              hint="Введите код"
              label="Код"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="flex-wrap flex-row-reverse">
            <v-btn text class="mx-2 text-none" color="grey darken-3" @click.stop="closeDialog">Закрыть</v-btn>
            <v-btn type="submit" depressed color="#e21a1a" class="white--text mr-3 text-RussianRail" @click="loginClientClick">Войти</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import ClientLoginDialog from '@/components/food/ClientLoginDialog';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      showClientLoginDialog: false,
      client: {
        email: '',
        authCode: ''
      },
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ]
      }
    };
  },

  computed: {
    getClientIconColor() {
      let color = 'grey';
      if (this.loginClientData !== null && this.loginClientData !== undefined) {
        color = 'red';
      }
      return color;
    },
    ...mapGetters('client', ['loginClientData']),
    ...mapGetters('client', ['loginClientResult'])
  },
  methods: {
    checkInput() {
      return true;
    },
    closeDialog() {
      this.client.email = '';
      this.client.authCode = '';
      this.showClientLoginDialog = false;
    },
    loginClientClick() {
      if (this.$refs.form.validate()) {
        this.loginClient(this.client).then(() => {
          if (this.loginClientResult === true) {
            this.closeDialog();
          }
        });
      }
    },
    clientLogoutClick() {
      this.logoutClient();
    },
    ...mapActions('client', ['loginClient']),
    ...mapActions('client', ['fillClientData']),
    ...mapActions('client', ['logoutClient'])
  },
  mounted() {
    this.fillClientData();
  }
};
</script>
