<template>
  <!-- <div> -->
  <v-card-actions class="d-flex justify-center align-end pa-2">
    <v-btn
      v-if="item.count === 0"
      :disabled="item.amountLeft === 0"
      elevation="0"
      block
      class="mx-2 text-none"
      color="red"
      text
      v-on:click="getIncrementCounter(item)"
      >{{ buttonText(item.amountLeft) }}</v-btn
    >
    <div v-if="item.count > 0" class="d-flex align-center">
      <v-btn class="mx-1" icon v-on:click="getDecrementCounter(item)"><v-icon color="red">mdi-minus</v-icon></v-btn>
      <v-card-text>{{ item.count }}</v-card-text>
      <v-btn class="mx-1" :disabled="item.count === item.amountLeft" icon v-on:click="getIncrementCounter(item)"><v-icon color="red">mdi-plus</v-icon></v-btn>
    </div>

    <v-dialog v-model="warningDialog" max-width="600">
      <v-card>
        <v-card-title> Предупреждение </v-card-title>
        <v-card-text>В вашей лежат товары другого ресторана. Чтобы добавить этот товар, нужно отчистить корзину.</v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="confirmClick(item)" class="mx-2 text-none" color="red" text elevation="0">Продолжить</v-btn>
          <v-btn @click="warningDialog = false" class="mx-2 text-none" text elevation="0">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-actions>
  <!-- </div> -->
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      warningDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentTakeouts() {
      let takeoutsArr = [];
      if (this.currentCafe !== undefined && this.currentCafe !== null) {
        this.currentCafe.takeouts.forEach((takeout) => takeoutsArr.push(takeout));
      }
      return takeoutsArr;
    },
    ...mapState('cafe', ['currentCafe'])
  },
  methods: {
    buttonText(amountLeft) {
      return amountLeft === 0 ? 'Будет позже' : 'Добавить';
    },
    getIncrementCounter(productData) {
      if (localStorage.getItem('cafeToken') === null || localStorage.getItem('cafeToken') === undefined) {
        this.setCafeToken(this.$route.params.token);
      }
      if (localStorage.getItem('cafeToken') === this.$route.params.token || (localStorage.getItem('cafeToken') && this.$route.params.token === undefined)) {
        this.addToCart(productData);
      }
      if (
        this.$route.params.token !== undefined &&
        this.$route.params.token !== null &&
        localStorage.getItem('cafeToken') !== this.$route.params.token &&
        localStorage.getItem('cafeToken') !== null &&
        localStorage.getItem('cafeToken') !== undefined
      ) {
        this.warningDialog = true;
      }
    },
    confirmClick(productData) {
      this.clearCart();
      this.setCafeToken(this.$route.params.token);
      this.addToCart(productData);
      localStorage.setItem('takeoutsArr', JSON.stringify(this.currentTakeouts));
      this.warningDialog = false;
    },
    getDecrementCounter(productData) {
      this.removeFromCart(productData);
    },
    ...mapActions('menu', ['addToCart']),
    ...mapActions('menu', ['setCafeToken']),
    ...mapActions('menu', ['removeFromCart']),
    ...mapActions('menu', ['clearCart'])
  }
};
</script>
