<template>
  <div class="MenuMain">
    <ClientHeader />
    <div v-if="this.isMenuListLoading === true && this.isMenuListError === false">Пожалуйста, подождите...</div>
    <div class="ma-12 d-flex justify-center" v-else-if="this.menuListResult === false && this.isMenuListError === true">
      {{ this.menuListErrorMessage }}
    </div>
    <div v-else-if="this.isMenuListError === false && this.isMenuListLoading === false && this.menuListResult === true" class="mx-6 FillingMenu">
      <v-breadcrumbs :items="breadItems"></v-breadcrumbs>
      <v-card rounded class="mx-auto mt-6 mb-3" max-width="700">
        <v-img
          class="white--text align-end mx-auto mt-6"
          v-if="cafeInfo.image !== undefined && cafeInfo.image !== null"
          :src="url() + this.cafeInfo.image.path"
          height="320px"
          whidt="180px"
          alt="изображение"
        >
          <div class="mb-2 d-flex">
            <v-card-title style="text-shadow: 2px 2px 2px #000" class="pb-2 pl-3 mr-auto">{{ this.cafeInfo.nameRu }}</v-card-title>
            <v-menu open-on-hover bottom offset-y max-width="200px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" class="mr-3" color="white" v-bind="attrs"> mdi-help-circle-outline </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <p>
                    {{ this.cafeInfo.agentName }} <br />
                    {{ this.cafeInfo.addressRu }} <br />
                    {{ this.cafeInfo.agentInn }} <br />
                    {{ this.cafeInfo.agentPhone }}
                  </p>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-img>
      </v-card>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="d-flex justify-center flex-wrap">
            <div v-for="category in categoriesWithSortedProduct" :key="category.token">
              <div v-if="category.products.length > 0">
                <v-btn-toggle group color="red" v-model="currCategory">
                  <v-btn class="mx-2" :value="category.token" @click="scrollTo(category.nameRu)">{{ category.nameRu }}</v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div v-for="item in this.categoriesWithSortedProduct" :key="item.id" class="my-12">
        <div v-if="item.products.length > 0">
          <h2 class="d-flex justify-center mb-4" :ref="item.nameRu">
            {{ item.nameRu }}
          </h2>
          <div></div>
          <ProductCard :product="item.products" />
        </div>
      </div>
      <ClientCart />
    </div>
    <div class="Footer">
      <ClientFooter />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ClientHeader from '@/components/food/ClientHeader';
import ProductCard from '@/components/food/ProductCard';
import ClientFooter from '@/components/food/ClientFooter';
import ClientCart from '@/components/food/ClientCart';
import config from '@/config/config';

export default {
  data() {
    return {
      order: {
        fullName: '',
        phone: '',
        mail: '',
        comment: '',
        cafeToken: '',
        takeoutToken: localStorage.getItem('takeout')
        // authCode: ''
      },
      enabled: false,
      errorMessage: '',
      breadItems: [
        {
          text: 'Вернуться к выбору ресторана',
          disabled: false,
          to: '/'
        }
      ],
      currCategory: -1,
      dialog: false,
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        numberForm: [(v) => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера']
      }
    };
  },
  components: { ClientHeader, ProductCard, ClientFooter, ClientCart },
  computed: {
    categoriesWithSortedProduct() {
      let categoriesArray = [];
      if (this.categoriesList !== undefined && this.currentCafe !== null && this.categoriesList.length > 0 && this.currentCafeResult === true) {
        this.categoriesList.forEach((category) => categoriesArray.push(category));
      }
      categoriesArray.map((category) => {
        category.products = this.products.filter((item) => {
          return item.categories.some((categoryObj) => categoryObj.token === category.token);
        });
      });
      return categoriesArray.sort((a, b) => a.position - b.position);
    },
    cafeInfo() {
      let item = {};
      if (this.currentCafe !== undefined && this.currentCafe !== null && this.currentCafeResult === true) {
        item = JSON.parse(JSON.stringify(this.currentCafe));
      }
      return item;
    },
    products() {
      const itemsArray = [];
      if (this.menuList !== undefined && this.menuList !== null && this.menuList.length > 0) {
        this.menuList.forEach((item) => itemsArray.push(item));
      }
      return itemsArray;
    },
    ...mapGetters('menu', ['menuList']),
    ...mapGetters('menu', ['isMenuListLoading']),
    ...mapGetters('menu', ['menuListResult']),
    ...mapGetters('menu', ['isMenuListError']),
    ...mapGetters('menu', ['menuListErrorMessage']),
    ...mapGetters('cafe', ['currentCafe']),
    ...mapGetters('cafe', ['currentCafeResult']),
    ...mapGetters('menu', ['categoriesList'])
  },
  methods: {
    scrollTo(refName) {
      var element = this.$refs[refName];
      var top = element[0].offsetTop;
      window.scrollTo(0, top);
    },
    url() {
      return window.location.origin + '/';
    },
    getMenuList() {
      this.fetchMenuList(this.$route.params.token);
    },
    getCafeInfo() {
      this.fetchCurrentCafe(this.$route.params.token);
    },
    ...mapActions('menu', ['addToCart']),
    ...mapActions('menu', ['setCafeToken']),
    ...mapActions('menu', ['removeFromCart']),

    ...mapActions('menu', ['incrementCounter']),
    ...mapActions('menu', ['decrementCounter']),
    ...mapActions('menu', ['fetchMenuList']),
    ...mapActions('cafe', ['fetchCurrentCafe']),
    ...mapActions('order', ['createOrder'])
  },
  mounted() {
    this.getMenuList();
    this.getCafeInfo();
  }
};
</script>
<style scoped>
.cards {
  /* margin-top: 20px; */
}
.MenuMain {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: RussianRail;
}
.FillingMenu {
  flex: 1 0 auto;
  margin-bottom: 10%;
}
.Footer {
  flex: 0 0 auto;
}
.BucketButton {
  position: fixed;
  bottom: 7%;
  right: 5%;
}
</style>
